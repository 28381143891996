/**
 * @author Akshay Kumar Singh
 * @file Summary Component
 * @flow
 */
import React, { Component } from 'react';
import {
    verifyAndgenerateReport,
    verifyAndgenerateIntermediateReport,
    downloadTaskRequest
} from '../../redux/actions/common_action';
import { LABLES, AUDIT_STATUS, ASSETS_TYPES_LIST } from '../../shared/constants/data';
import { encryptData } from '../../shared/utils/crypto.util';
import ROUTES from '../../shared/constants/routes';
import {
    formatDate,
    checkAuditStatus,
    checkTaskStatus,
    getAuditId
} from '../../shared/utils/helper.util';
import { getData } from '../../shared/utils/storage.util';
import { Line } from 'rc-progress';
import CONFIG from '../../configuration';
import LANGUAGECONST from '../../shared/constants/language';
import Axios from 'axios';
import AlertModal from '../../shared/modals/AlertModal';
import { fetchHeightsTable } from '../../Apis/heightsApi';
import { fetchMeasurementsTable } from '../../Apis/measurementsApi';

class Summary extends Component {
    /**
     * @description This function used to redirect to audit detail page.
     * @param plantName Name of plant
     * @param auditId That is audit id.
     */
    redirectToDetails = (plantName, auditId) => {
        const route = ROUTES.AUDITDETAILS.replace(
            ':plantName/:auditId',
            encryptData(plantName) + '/' + encryptData(auditId)
        );
        /* navigate to details screen */
        this.props.history.push(route);
    };

    /**
     * @description This function used to download task.
     * @param e Event that is use to defaut prevent.
     * @param assetId That is asset ID.
     */
    downloadTask = async (e, userListData) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        e.preventDefault();
        let data = {
            asset_type_id: ASSETS_TYPES_LIST[userListData.task_name],
            user_email: getData('loginData').userData.email,
            user_first_name: getData('loginData').userData.first_name,
            task_id: userListData.task_id,
            audit_name: userListData.audit_name,
            asset_name: userListData.asset
        };

        downloadTaskRequest(CONFIG.downloadTask, data, language);
    };

    /**
     * @description This function used to generate report.
     * @param e Event that is use to defaut prevent.
     * @param assetId That is asset ID.
     */
    generateReport = async (e, assetId) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        e.preventDefault();
        let data = {
            audit_id: this.props.auditListData.audit_id,
            user_id: getData('loginData').userData.user_id
        };

        const fileName = `Report_${this.props.auditListData.audit_name}.pptx`;
        const heightsTable = await fetchHeightsTable(data.audit_id);
        const measurements = await fetchMeasurementsTable(data.audit_id);
        let heightsInfo = { ...heightsTable, measurements };
        verifyAndgenerateReport(
            CONFIG.verifyGenerateReport,
            data,
            assetId,
            lang.STRINGS.GENERATINGREPORT,
            fileName,
            this,
            language,
            heightsInfo
        );
    };

    generateIntermediateReport = async (e, reportsList) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        e.preventDefault();
        let data = {
            audit_id: reportsList.audit_id,
            task_id: reportsList.task_id,
            user_id: getData('loginData').userData.user_id
        };

        const fileName = `Report_${reportsList.audit_name}.pptx`;
        verifyAndgenerateIntermediateReport(
            CONFIG.generateIntermediateReport,
            data,
            lang.STRINGS.GENERATINGINTERMEDIATEREPORT,
            fileName,
            this,
            language
        );
    };

    generateIntermediateReport = async (e, reportsList) => {
        e.preventDefault();
        let data = {
            audit_id: reportsList.audit_id,
            task_id: reportsList.task_id,
            user_id: getData('loginData').userData.user_id
        };

        const fileName = `Report_${reportsList.audit_name}.pptx`;
        verifyAndgenerateIntermediateReport(
            CONFIG.generateIntermediateReport,
            data,
            'Generating Intermediate Report',
            fileName,
            this
        );
    };

    render() {
        const mystyle = {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            position: 'absolute',
            fontWeight: 'bold'
        };
        const userRole = getData('loginData').userData.role_name;
        const activeTab = getData('checkPage');
        const {
            auditListData,
            userListData,
            auditorTaskList,
            reviewerTaskList,
            plantManagerTaskList,
            actions,
            showReAssignButton,
            reAssign
        } = this.props;
        let checkStatusClass =
            userListData &&
            (userListData.task_status == 2
                ? 'reviewerClass'
                : userListData.task_status == 3
                ? 'completedClass'
                : '');
        /* For Auditor and Reviewer */
        function _renderComponentByRole(self) {
            const taskData =
                userRole === 'auditor'
                    ? auditorTaskList
                    : userRole == 'plant manager'
                    ? plantManagerTaskList
                    : reviewerTaskList;
            let statusClass =
                taskData &&
                (taskData.task_status == 2
                    ? 'reviewerClass'
                    : taskData.task_status == 3
                    ? 'completedClass'
                    : '');
            return (
                <div className={`summary-boxes ${statusClass}`}>
                    <ul>
                        <li>
                            {
                                LANGUAGECONST[self.props?.langData?.langType || 'ENG'].LABLES
                                    .AuditName
                            }{' '}
                            : <strong>{taskData.audit_name}</strong>
                        </li>
                        <li>
                            {
                                LANGUAGECONST[self.props?.langData?.langType || 'ENG'].LABLES
                                    .PlantName
                            }{' '}
                            : <strong>{taskData.plant_name}</strong>
                        </li>
                        <li>
                            {
                                LANGUAGECONST[self.props?.langData?.langType || 'ENG'].LABLES
                                    .AuditBeginDate
                            }{' '}
                            : <strong>{formatDate(taskData.start_date)}</strong>
                        </li>
                        <li>
                            {
                                LANGUAGECONST[self.props?.langData?.langType || 'ENG'].LABLES
                                    .AuditEndDate
                            }{' '}
                            : <strong>{formatDate(taskData.end_date)}</strong>
                        </li>
                        <li>
                            {
                                LANGUAGECONST[self.props?.langData?.langType || 'ENG'].LABLES
                                    .AuditType
                            }{' '}
                            : <strong>{taskData.task_name}</strong>
                        </li>
                        {getAuditId(taskData.task_name) == 1 ? (
                            <li>
                                {
                                    LANGUAGECONST[self.props?.langData?.langType || 'ENG'].LABLES
                                        .WOStartDate
                                }
                                : <strong>{taskData.task_start_date}</strong>
                            </li>
                        ) : null}
                        {getAuditId(taskData.task_name) == 1 ? (
                            <li>
                                {
                                    LANGUAGECONST[self.props?.langData?.langType || 'ENG'].LABLES
                                        .WOEndDate
                                }
                                : <strong>{taskData.task_end_date}</strong>
                            </li>
                        ) : null}
                        <li>
                            {LANGUAGECONST[self.props?.langData?.langType || 'ENG'].LABLES.Asset} :{' '}
                            <strong>{taskData.asset}</strong>
                        </li>
                        <li>
                            {LANGUAGECONST[self.props?.langData?.langType || 'ENG'].LABLES.Auditor}{' '}
                            : <strong>{taskData.auditor_name}</strong>
                        </li>
                        <li>
                            {LANGUAGECONST[self.props?.langData?.langType || 'ENG'].LABLES.Status} :{' '}
                            <strong>{checkTaskStatus(taskData.task_status)}</strong>
                        </li>
                        <li>
                            {LANGUAGECONST[self.props?.langData?.langType || 'ENG'].LABLES.PROGRESS}{' '}
                            : <strong>{taskData.percentage}%</strong>{' '}
                            <Line
                                percent={taskData.percentage}
                                strokeWidth="4"
                                strokeColor="#3FC7FA"
                            />
                        </li>
                    </ul>
                    <div className="summary-btn">
                        {showReAssignButton && (
                            <button className="btn btn-prmry" onClick={() => reAssign(taskData)}>
                                {
                                    LANGUAGECONST[self.props?.langData?.langType || 'ENG'].LABLES
                                        .Reassign
                                }
                            </button>
                        )}
                        <button
                            className="btn btn-prmry"
                            data-name={taskData.audit_name}
                            data-activity={taskData.activity}
                            onClick={() => actions.redirectToReviewTask(taskData)}>
                            {LANGUAGECONST[self.props?.langData?.langType || 'ENG'].LABLES.ViewTask}
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div className="column-four">
                {userRole === 'audit manager' ? (
                    /* Audit Manager */
                    activeTab !== 2 ? (
                        <div className="summary-boxes">
                            <ul>
                                <li>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.AuditName
                                    }{' '}
                                    : <strong>{auditListData.audit_name}</strong>
                                </li>
                                <li>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.PlantName
                                    }{' '}
                                    : <strong>{auditListData.plant_name}</strong>
                                </li>
                                <li>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.AuditBeginDate
                                    }{' '}
                                    : <strong>{formatDate(auditListData.start_date)}</strong>
                                </li>
                                <li>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.AuditEndDate
                                    }{' '}
                                    : <strong>{formatDate(auditListData.end_date)}</strong>
                                </li>
                                <li>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.AuditManagers
                                    }{' '}
                                    : <strong>{auditListData.created_by}</strong>
                                </li>
                                <li>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.Status
                                    }{' '}
                                    : <strong>{checkAuditStatus(auditListData.status)}</strong>
                                </li>
                                <li>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.PROGRESS
                                    }{' '}
                                    : <strong>{auditListData.percentage}%</strong>{' '}
                                    <Line
                                        percent={auditListData.percentage}
                                        strokeWidth="4"
                                        strokeColor="#3FC7FA"
                                    />
                                </li>
                            </ul>
                            <div className="summary-btn">
                                {auditListData.status === AUDIT_STATUS['COMPLETED'] ||
                                auditListData.status === AUDIT_STATUS['IN_PROGRESS'] ? (
                                    <a
                                        href="!#"
                                        onClick={(e) =>
                                            this.generateReport(e, auditListData.audit_id)
                                        }
                                        download>
                                        {
                                            LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                                .LABLES.GenerateReport
                                        }
                                    </a>
                                ) : (
                                    ''
                                )}
                                <button
                                    className="btn btn-prmry"
                                    data-name={auditListData.audit_name}
                                    onClick={() =>
                                        this.props.actions.redirectToDetails(
                                            auditListData,
                                            activeTab
                                        )
                                    }>
                                    {activeTab === 2
                                        ? LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                              .LABLES.ViewTask
                                        : LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                              .LABLES.ViewAudit}{' '}
                                </button>
                                {(!activeTab || activeTab == 1) && (
                                    <button
                                        className="btn btn-prmry"
                                        onClick={() => this.props.deleteAudit(auditListData)}>
                                        {
                                            LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                                .LABLES.DeleteAudit
                                        }
                                    </button>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className={`summary-boxes ${checkStatusClass}`}>
                            <ul>
                                <li>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.AuditName
                                    }{' '}
                                    : <strong>{userListData.audit_name}</strong>
                                </li>
                                <li>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.PlantName
                                    }{' '}
                                    : <strong>{userListData.plant_name}</strong>
                                </li>
                                <li>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.AuditBeginDate
                                    }{' '}
                                    : <strong>{formatDate(userListData.start_date)}</strong>
                                </li>
                                <li>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.AuditEndDate
                                    }{' '}
                                    : <strong>{formatDate(userListData.end_date)}</strong>
                                </li>
                                <li>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.AuditType
                                    }{' '}
                                    : <strong>{userListData.task_name}</strong>
                                </li>
                                {getAuditId(userListData.task_name) == 1 ? (
                                    <li>
                                        {
                                            LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                                .LABLES.WOStartDate
                                        }
                                        : <strong>{this.props.userListData.task_start_date}</strong>
                                    </li>
                                ) : null}
                                {getAuditId(userListData.task_name) == 1 ? (
                                    <li>
                                        {
                                            LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                                .LABLES.WOEndDate
                                        }
                                        : <strong>{this.props.userListData.task_end_date}</strong>
                                    </li>
                                ) : null}
                                {userListData.asset != 'NA' ? (
                                    <li>
                                        {
                                            LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                                .LABLES.Asset
                                        }{' '}
                                        : <strong>{userListData.asset}</strong>
                                    </li>
                                ) : null}
                                {userListData.activity != 'NA' ? (
                                    <li>
                                        {
                                            LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                                .LABLES.Activity
                                        }{' '}
                                        : <strong>{userListData.activity}</strong>
                                    </li>
                                ) : null}
                                {userListData.task_name == 3 ? (
                                    <li>
                                        {
                                            LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                                .LABLES.Asset
                                        }
                                        : <strong>{this.props.userListData.asset}</strong>
                                    </li>
                                ) : null}
                                <li>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.Reviewer
                                    }{' '}
                                    : <strong>{userListData.reviewer_name}</strong>
                                </li>
                                <li>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.Status
                                    }{' '}
                                    : <strong>{checkTaskStatus(userListData.task_status)}</strong>
                                </li>
                                <li>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.PROGRESS
                                    }{' '}
                                    : <strong>{userListData.percentage}%</strong>{' '}
                                    <Line
                                        percent={userListData.percentage}
                                        strokeWidth="4"
                                        strokeColor="#3FC7FA"
                                    />
                                </li>
                            </ul>
                            <div className="summary-btn">
                                {checkTaskStatus(userListData.task_status) === 'In Review' ? (
                                    <a
                                        href="!#"
                                        onClick={(e) =>
                                            this.generateIntermediateReport(e, userListData)
                                        }
                                        download>
                                        {
                                            LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                                .LABLES.GenerateIntermediateReport
                                        }
                                    </a>
                                ) : (
                                    ''
                                )}
                                <button
                                    className="btn btn-prmry"
                                    data-name={userListData.audit_name}
                                    data-activity={userListData.activity}
                                    onClick={() =>
                                        actions.redirectToViewTask(userListData, activeTab)
                                    }>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.ViewTask
                                    }
                                </button>
                                <button
                                    className="btn btn-prmry"
                                    data-name={userListData.audit_name}
                                    data-activity={userListData.activity}
                                    onClick={(e) => this.downloadTask(e, userListData)}>
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.DownloadTask
                                    }
                                </button>
                            </div>
                        </div>
                    )
                ) : userRole === 'reviewer' ? (
                    /* Reviewer */
                    reviewerTaskList.task_status == 2 || reviewerTaskList.task_status == 3 ? (
                        _renderComponentByRole(this)
                    ) : (
                        <div style={mystyle}>
                            <ul>
                                <li></li>
                            </ul>
                        </div>
                    )
                ) : (
                    /* Auditor */
                    _renderComponentByRole(this)
                )}
            </div>
        );
    }
}
export default Summary;
